// gutters
// margin
.margin-1{margin: 1px !important;}
.margin-2{margin: 2px !important;}
.margin-3{margin: 3px !important;}
.margin-4{margin: 4px !important;}
.margin-5{margin: 5px !important;}
.margin-6{margin: 6px !important;}
.m-7{margin: 7px !important;}
.m-8{margin: 8px !important;}
.m-9{margin: 9px !important;}
.m-10{margin: 10px !important;}
.m-11{margin: 11px !important;}
.m-12{margin: 12px !important;}
.m-13{margin: 13px !important;}
.m-14{margin: 14px !important;}
.m-15{margin: 15px !important;}
.m-16{margin: 16px !important;}
.m-17{margin: 17px !important;}
.m-18{margin: 18px !important;}
.m-19{margin: 19px !important;}
.m-20{margin: 20px !important;}
.m-21{margin: 21px !important;}
.m-22{margin: 22px !important;}
.m-23{margin: 23px !important;}
.m-24{margin: 24px !important;}
.m-25{margin: 25px !important;}
.m-26{margin: 26px !important;}
.m-27{margin: 27px !important;}
.m-28{margin: 28px !important;}
.m-29{margin: 29px !important;}
.m-30{margin: 30px !important;}
.m-40{margin: 40px !important;}
.m-50{margin: 50px !important;}
// margin-left
.m-l-1{margin-left: 1px !important;}
.m-l-2{margin-left: 2px !important;}
.m-l-3{margin-left: 3px !important;}
.m-l-4{margin-left: 4px !important;}
.m-l-5{margin-left: 5px !important;}
.m-l-6{margin-left: 6px !important;}
.m-l-7{margin-left: 7px !important;}
.m-l-8{margin-left: 8px !important;}
.m-l-9{margin-left: 9px !important;}
.m-l-10{margin-left: 10px !important;}
.m-l-11{margin-left: 11px !important;}
.m-l-12{margin-left: 12px !important;}
.m-l-13{margin-left: 13px !important;}
.m-l-14{margin-left: 14px !important;}
.m-l-15{margin-left: 15px !important;}
.m-l-16{margin-left: 16px !important;}
.m-l-17{margin-left: 17px !important;}
.m-l-18{margin-left: 18px !important;}
.m-l-19{margin-left: 19px !important;}
.m-l-20{margin-left: 20px !important;}
.m-l-21{margin-left: 21px !important;}
.m-l-22{margin-left: 22px !important;}
.m-l-23{margin-left: 23px !important;}
.m-l-24{margin-left: 24px !important;}
.m-l-25{margin-left: 25px !important;}
.m-l-26{margin-left: 26px !important;}
.m-l-27{margin-left: 27px !important;}
.m-l-28{margin-left: 28px !important;}
.m-l-29{margin-left: 29px !important;}
.m-l-30{margin-left: 30px !important;}
.m-l-40{margin-left: 40px !important;}
.m-l-50{margin-left: 50px !important;}
// margin-right
.m-r-1{margin-right: 1px !important;}
.m-r-2{margin-right: 2px !important;}
.m-r-3{margin-right: 3px !important;}
.m-r-4{margin-right: 4px !important;}
.m-r-5{margin-right: 5px !important;}
.m-r-6{margin-right: 6px !important;}
.m-r-7{margin-right: 7px !important;}
.m-r-8{margin-right: 8px !important;}
.m-r-9{margin-right: 9px !important;}
.m-r-10{margin-right: 10px !important;}
.m-r-11{margin-right: 11px !important;}
.m-r-12{margin-right: 12px !important;}
.m-r-13{margin-right: 13px !important;}
.m-r-14{margin-right: 14px !important;}
.m-r-15{margin-right: 15px !important;}
.m-r-16{margin-right: 16px !important;}
.m-r-17{margin-right: 17px !important;}
.m-r-18{margin-right: 18px !important;}
.m-r-19{margin-right: 19px !important;}
.m-r-20{margin-right: 20px !important;}
.m-r-21{margin-right: 21px !important;}
.m-r-22{margin-right: 22px !important;}
.m-r-23{margin-right: 23px !important;}
.m-r-24{margin-right: 24px !important;}
.m-r-25{margin-right: 25px !important;}
.m-r-26{margin-right: 26px !important;}
.m-r-27{margin-right: 27px !important;}
.m-r-28{margin-right: 28px !important;}
.m-r-29{margin-right: 29px !important;}
.m-r-30{margin-right: 30px !important;}
.m-r-40{margin-right: 40px !important;}
.m-r-50{margin-right: 50px !important;}
// margin-bottom
.m-b-1{margin-bottom: 1px !important;}
.m-b-2{margin-bottom: 2px !important;}
.m-b-3{margin-bottom: 3px !important;}
.m-b-4{margin-bottom: 4px !important;}
.m-b-5{margin-bottom: 5px !important;}
.m-b-6{margin-bottom: 6px !important;}
.m-b-7{margin-bottom: 7px !important;}
.m-b-8{margin-bottom: 8px !important;}
.m-b-9{margin-bottom: 9px !important;}
.m-b-10{margin-bottom: 10px !important;}
.m-b-11{margin-bottom: 11px !important;}
.m-b-12{margin-bottom: 12px !important;}
.m-b-13{margin-bottom: 13px !important;}
.m-b-14{margin-bottom: 14px !important;}
.m-b-15{margin-bottom: 15px !important;}
.m-b-16{margin-bottom: 16px !important;}
.m-b-17{margin-bottom: 17px !important;}
.m-b-18{margin-bottom: 18px !important;}
.m-b-19{margin-bottom: 19px !important;}
.m-b-20{margin-bottom: 20px !important;}
.m-b-21{margin-bottom: 21px !important;}
.m-b-22{margin-bottom: 22px !important;}
.m-b-23{margin-bottom: 23px !important;}
.m-b-24{margin-bottom: 24px !important;}
.m-b-25{margin-bottom: 25px !important;}
.m-b-26{margin-bottom: 26px !important;}
.m-b-27{margin-bottom: 27px !important;}
.m-b-28{margin-bottom: 28px !important;}
.m-b-29{margin-bottom: 29px !important;}
.m-b-30{margin-bottom: 30px !important;}
.m-b-40{margin-bottom: 40px !important;}
.m-b-50{margin-bottom: 50px !important;}
// margin-top
.m-t-0{margin-top: 0 !important;}
.m-t-1{margin-top: 1px !important;}
.m-t-2{margin-top: 2px !important;}
.m-t-3{margin-top: 3px !important;}
.m-t-4{margin-top: 4px !important;}
.m-t-5{margin-top: 5px !important;}
.m-t-6{margin-top: 6px !important;}
.m-t-7{margin-top: 7px !important;}
.m-t-8{margin-top: 8px !important;}
.m-t-9{margin-top: 9px !important;}
.m-t-10{margin-top: 10px !important;}
.m-t-11{margin-top: 11px !important;}
.m-t-12{margin-top: 12px !important;}
.m-t-13{margin-top: 13px !important;}
.m-t-14{margin-top: 14px !important;}
.m-t-15{margin-top: 15px !important;}
.m-t-16{margin-top: 16px !important;}
.m-t-17{margin-top: 17px !important;}
.m-t-18{margin-top: 18px !important;}
.m-t-19{margin-top: 19px !important;}
.m-t-20{margin-top: 20px !important;}
.m-t-21{margin-top: 21px !important;}
.m-t-22{margin-top: 22px !important;}
.m-t-23{margin-top: 23px !important;}
.m-t-24{margin-top: 24px !important;}
.m-t-25{margin-top: 25px !important;}
.m-t-26{margin-top: 26px !important;}
.m-t-27{margin-top: 27px !important;}
.m-t-28{margin-top: 28px !important;}
.m-t-29{margin-top: 29px !important;}
.m-t-30{margin-top: 30px !important;}
.m-t-40{margin-top: 40px !important;}
.m-t-50{margin-top: 50px !important;}
// padding
.padding-1{padding: 1px !important;}
.padding-2{padding: 2px !important;}
.padding-3{padding: 3px !important;}
.padding-4{padding: 4px !important;}
.padding-5{padding: 5px !important;}
.padding-6{padding: 6px !important;}
.p-7{padding: 7px !important;}
.p-8{padding: 8px !important;}
.p-9{padding: 9px !important;}
.p-10{padding: 10px !important;}
.p-11{padding: 11px !important;}
.p-12{padding: 12px !important;}
.p-13{padding: 13px !important;}
.p-14{padding: 14px !important;}
.p-15{padding: 15px !important;}
.p-16{padding: 16px !important;}
.p-17{padding: 17px !important;}
.p-18{padding: 18px !important;}
.p-19{padding: 19px !important;}
.p-20{padding: 20px !important;}
.p-21{padding: 21px !important;}
.p-22{padding: 22px !important;}
.p-23{padding: 23px !important;}
.p-24{padding: 24px !important;}
.p-25{padding: 25px !important;}
.p-26{padding: 26px !important;}
.p-27{padding: 27px !important;}
.p-28{padding: 28px !important;}
.p-29{padding: 29px !important;}
.p-30{padding: 30px !important;}
.p-40{padding: 40px !important;}
.p-50{padding: 50px !important;}
// padding-left
.p-l-1{padding-left: 1px !important;}
.p-l-2{padding-left: 2px !important;}
.p-l-3{padding-left: 3px !important;}
.p-l-4{padding-left: 4px !important;}
.p-l-5{padding-left: 5px !important;}
.p-l-6{padding-left: 6px !important;}
.p-l-7{padding-left: 7px !important;}
.p-l-8{padding-left: 8px !important;}
.p-l-9{padding-left: 9px !important;}
.p-l-10{padding-left: 10px !important;}
.p-l-11{padding-left: 11px !important;}
.p-l-12{padding-left: 12px !important;}
.p-l-13{padding-left: 13px !important;}
.p-l-14{padding-left: 14px !important;}
.p-l-15{padding-left: 15px !important;}
.p-l-16{padding-left: 16px !important;}
.p-l-17{padding-left: 17px !important;}
.p-l-18{padding-left: 18px !important;}
.p-l-19{padding-left: 19px !important;}
.p-l-20{padding-left: 20px !important;}
.p-l-21{padding-left: 21px !important;}
.p-l-22{padding-left: 22px !important;}
.p-l-23{padding-left: 23px !important;}
.p-l-24{padding-left: 24px !important;}
.p-l-25{padding-left: 25px !important;}
.p-l-26{padding-left: 26px !important;}
.p-l-27{padding-left: 27px !important;}
.p-l-28{padding-left: 28px !important;}
.p-l-29{padding-left: 29px !important;}
.p-l-30{padding-left: 30px !important;}
.p-l-40{padding-left: 40px !important;}
.p-l-43{padding-left: 43px !important;}
.p-l-50{padding-left: 50px !important;}
// padding-right
.p-r-1{padding-right: 1px !important;}
.p-r-2{padding-right: 2px !important;}
.p-r-3{padding-right: 3px !important;}
.p-r-4{padding-right: 4px !important;}
.p-r-5{padding-right: 5px !important;}
.p-r-6{padding-right: 6px !important;}
.p-r-7{padding-right: 7px !important;}
.p-r-8{padding-right: 8px !important;}
.p-r-9{padding-right: 9px !important;}
.p-r-10{padding-right: 10px !important;}
.p-r-11{padding-right: 11px !important;}
.p-r-12{padding-right: 12px !important;}
.p-r-13{padding-right: 13px !important;}
.p-r-14{padding-right: 14px !important;}
.p-r-15{padding-right: 15px !important;}
.p-r-16{padding-right: 16px !important;}
.p-r-17{padding-right: 17px !important;}
.p-r-18{padding-right: 18px !important;}
.p-r-19{padding-right: 19px !important;}
.p-r-20{padding-right: 20px !important;}
.p-r-21{padding-right: 21px !important;}
.p-r-22{padding-right: 22px !important;}
.p-r-23{padding-right: 23px !important;}
.p-r-24{padding-right: 24px !important;}
.p-r-25{padding-right: 25px !important;}
.p-r-26{padding-right: 26px !important;}
.p-r-27{padding-right: 27px !important;}
.p-r-28{padding-right: 28px !important;}
.p-r-29{padding-right: 29px !important;}
.p-r-30{padding-right: 30px !important;}
.p-r-40{padding-right: 40px !important;}
.p-r-50{padding-right: 50px !important;}
// padding-bottom
.p-b-1{padding-bottom: 1px !important;}
.p-b-2{padding-bottom: 2px !important;}
.p-b-3{padding-bottom: 3px !important;}
.p-b-4{padding-bottom: 4px !important;}
.p-b-5{padding-bottom: 5px !important;}
.p-b-6{padding-bottom: 6px !important;}
.p-b-7{padding-bottom: 7px !important;}
.p-b-8{padding-bottom: 8px !important;}
.p-b-9{padding-bottom: 9px !important;}
.p-b-10{padding-bottom: 10px !important;}
.p-b-11{padding-bottom: 11px !important;}
.p-b-12{padding-bottom: 12px !important;}
.p-b-13{padding-bottom: 13px !important;}
.p-b-14{padding-bottom: 14px !important;}
.p-b-15{padding-bottom: 15px !important;}
.p-b-16{padding-bottom: 16px !important;}
.p-b-17{padding-bottom: 17px !important;}
.p-b-18{padding-bottom: 18px !important;}
.p-b-19{padding-bottom: 19px !important;}
.p-b-20{padding-bottom: 20px !important;}
.p-b-21{padding-bottom: 21px !important;}
.p-b-22{padding-bottom: 22px !important;}
.p-b-23{padding-bottom: 23px !important;}
.p-b-24{padding-bottom: 24px !important;}
.p-b-25{padding-bottom: 25px !important;}
.p-b-26{padding-bottom: 26px !important;}
.p-b-27{padding-bottom: 27px !important;}
.p-b-28{padding-bottom: 28px !important;}
.p-b-29{padding-bottom: 29px !important;}
.p-b-30{padding-bottom: 30px !important;}
.p-b-40{padding-bottom: 40px !important;}
.p-b-50{padding-bottom: 50px !important;}
// padding-top
.p-t-1{padding-top: 1px !important;}
.p-t-2{padding-top: 2px !important;}
.p-t-3{padding-top: 3px !important;}
.p-t-4{padding-top: 4px !important;}
.p-t-5{padding-top: 5px !important;}
.p-t-6{padding-top: 6px !important;}
.p-t-7{padding-top: 7px !important;}
.p-t-8{padding-top: 8px !important;}
.p-t-9{padding-top: 9px !important;}
.p-t-10{padding-top: 10px !important;}
.p-t-11{padding-top: 11px !important;}
.p-t-12{padding-top: 12px !important;}
.p-t-13{padding-top: 13px !important;}
.p-t-14{padding-top: 14px !important;}
.p-t-15{padding-top: 15px !important;}
.p-t-16{padding-top: 16px !important;}
.p-t-17{padding-top: 17px !important;}
.p-t-18{padding-top: 18px !important;}
.p-t-19{padding-top: 19px !important;}
.p-t-20{padding-top: 20px !important;}
.p-t-21{padding-top: 21px !important;}
.p-t-22{padding-top: 22px !important;}
.p-t-23{padding-top: 23px !important;}
.p-t-24{padding-top: 24px !important;}
.p-t-25{padding-top: 25px !important;}
.p-t-26{padding-top: 26px !important;}
.p-t-27{padding-top: 27px !important;}
.p-t-28{padding-top: 28px !important;}
.p-t-29{padding-top: 29px !important;}
.p-t-30{padding-top: 30px !important;}
.p-t-40{padding-top: 40px !important;}
.p-t-50{padding-top: 50px !important;}
.p-t-55{padding-top: 55px !important;}

// position top
.top-1{top: 1px !important;}
.top-2{top: 2px !important;}
.top-3{top: 3px !important;}
.top-4{top: 4px !important;}
.top-5{top: 5px !important;}
.top-6{top: 6px !important;}
.top-7{top: 7px !important;}
.top-8{top: 8px !important;}
.top-9{top: 9px !important;}
.top-10{top: 10px !important;}
.top-11{top: 11px !important;}
.top-12{top: 12px !important;}
.top-13{top: 13px !important;}
.top-14{top: 14px !important;}
.top-15{top: 15px !important;}
.top-16{top: 16px !important;}
.top-17{top: 17px !important;}
.top-18{top: 18px !important;}
.top-19{top: 19px !important;}
.top-20{top: 20px !important;}
.top-21{top: 21px !important;}
.top-22{top: 22px !important;}
.top-23{top: 23px !important;}
.top-24{top: 24px !important;}
.top-25{top: 25px !important;}
.top-26{top: 26px !important;}
.top-27{top: 27px !important;}
.top-28{top: 28px !important;}
.top-29{top: 29px !important;}
.top-30{top: 30px !important;}
.top-31{top: 31px !important;}
.top-32{top: 32px !important;}
.top-33{top: 33px !important;}
.top-34{top: 34px !important;}
.top-35{top: 35px !important;}
.top-36{top: 36px !important;}
.top-37{top: 37px !important;}
.top-38{top: 38px !important;}
.top-39{top: 39px !important;}
.top-40{top: 40px !important;}
.top-41{top: 41px !important;}
.top-42{top: 42px !important;}
.top-43{top: 43px !important;}
.top-44{top: 44px !important;}
.top-45{top: 45px !important;}
// position right
.right-1{right: 1px !important;}
.right-2{right: 2px !important;}
.right-3{right: 3px !important;}
.right-4{right: 4px !important;}
.right-5{right: 5px !important;}
.right-6{right: 6px !important;}
.right-7{right: 7px !important;}
.right-8{right: 8px !important;}
.right-9{right: 9px !important;}
.right-10{right: 10px !important;}
.right-11{right: 11px !important;}
.right-12{right: 12px !important;}
.right-13{right: 13px !important;}
.right-14{right: 14px !important;}
.right-15{right: 15px !important;}
.right-16{right: 16px !important;}
.right-17{right: 17px !important;}
.right-18{right: 18px !important;}
.right-19{right: 19px !important;}
.right-20{right: 20px !important;}
.right-21{right: 21px !important;}
.right-22{right: 22px !important;}
.right-23{right: 23px !important;}
.right-24{right: 24px !important;}
.right-25{right: 25px !important;}
.right-26{right: 26px !important;}
.right-27{right: 27px !important;}
.right-28{right: 28px !important;}
.right-29{right: 29px !important;}
.right-30{right: 30px !important;}
.right-31{right: 31px !important;}
.right-32{right: 32px !important;}
.right-33{right: 33px !important;}
.right-34{right: 34px !important;}
.right-35{right: 35px !important;}
.right-36{right: 36px !important;}
.right-37{right: 37px !important;}
.right-38{right: 38px !important;}
.right-39{right: 39px !important;}
.right-40{right: 40px !important;}
.right-41{right: 41px !important;}
.right-42{right: 42px !important;}
.right-43{right: 43px !important;}
.right-44{right: 44px !important;}
.right-45{right: 45px !important;}
// position bottom
.bottom-1{bottom: 1px !important;}
.bottom-2{bottom: 2px !important;}
.bottom-3{bottom: 3px !important;}
.bottom-4{bottom: 4px !important;}
.bottom-5{bottom: 5px !important;}
.bottom-6{bottom: 6px !important;}
.bottom-7{bottom: 7px !important;}
.bottom-8{bottom: 8px !important;}
.bottom-9{bottom: 9px !important;}
.bottom-10{bottom: 10px !important;}
.bottom-11{bottom: 11px !important;}
.bottom-12{bottom: 12px !important;}
.bottom-13{bottom: 13px !important;}
.bottom-14{bottom: 14px !important;}
.bottom-15{bottom: 15px !important;}
.bottom-16{bottom: 16px !important;}
.bottom-17{bottom: 17px !important;}
.bottom-18{bottom: 18px !important;}
.bottom-19{bottom: 19px !important;}
.bottom-20{bottom: 20px !important;}
.bottom-21{bottom: 21px !important;}
.bottom-22{bottom: 22px !important;}
.bottom-23{bottom: 23px !important;}
.bottom-24{bottom: 24px !important;}
.bottom-25{bottom: 25px !important;}
.bottom-26{bottom: 26px !important;}
.bottom-27{bottom: 27px !important;}
.bottom-28{bottom: 28px !important;}
.bottom-29{bottom: 29px !important;}
.bottom-30{bottom: 30px !important;}
.bottom-31{bottom: 31px !important;}
.bottom-32{bottom: 32px !important;}
.bottom-33{bottom: 33px !important;}
.bottom-34{bottom: 34px !important;}
.bottom-35{bottom: 35px !important;}
.bottom-36{bottom: 36px !important;}
.bottom-37{bottom: 37px !important;}
.bottom-38{bottom: 38px !important;}
.bottom-39{bottom: 39px !important;}
.bottom-40{bottom: 40px !important;}
.bottom-41{bottom: 41px !important;}
.bottom-42{bottom: 42px !important;}
.bottom-43{bottom: 43px !important;}
.bottom-44{bottom: 44px !important;}
.bottom-45{bottom: 45px !important;}
// position left
.left-1{left: 1px !important;}
.left-2{left: 2px !important;}
.left-3{left: 3px !important;}
.left-4{left: 4px !important;}
.left-5{left: 5px !important;}
.left-6{left: 6px !important;}
.left-7{left: 7px !important;}
.left-8{left: 8px !important;}
.left-9{left: 9px !important;}
.left-10{left: 10px !important;}
.left-11{left: 11px !important;}
.left-12{left: 12px !important;}
.left-13{left: 13px !important;}
.left-14{left: 14px !important;}
.left-15{left: 15px !important;}
.left-16{left: 16px !important;}
.left-17{left: 17px !important;}
.left-18{left: 18px !important;}
.left-19{left: 19px !important;}
.left-20{left: 20px !important;}
.left-21{left: 21px !important;}
.left-22{left: 22px !important;}
.left-23{left: 23px !important;}
.left-24{left: 24px !important;}
.left-25{left: 25px !important;}
.left-26{left: 26px !important;}
.left-27{left: 27px !important;}
.left-28{left: 28px !important;}
.left-29{left: 29px !important;}
.left-30{left: 30px !important;}
.left-31{left: 31px !important;}
.left-32{left: 32px !important;}
.left-33{left: 33px !important;}
.left-34{left: 34px !important;}
.left-35{left: 35px !important;}
.left-36{left: 36px !important;}
.left-37{left: 37px !important;}
.left-38{left: 38px !important;}
.left-39{left: 39px !important;}
.left-40{left: 40px !important;}
.left-41{left: 41px !important;}
.left-42{left: 42px !important;}
.left-43{left: 43px !important;}
.left-44{left: 44px !important;}
.left-45{left: 45px !important;}