@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.text-link {
  color: #025c99;
}

.bg-gray {
  background: #f3f2f1;
}

.cardEffect {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  border: none;

  .card-header {
    // font-weight: 600;
    font-size: 16px;
  }

  table th {
    color: #333;
    font-size: 0.875rem;
  }

  table td {
    color: #333;
    font-size: 0.875rem;
    word-wrap: normal;
    line-height: initial;
  }
}

.cardEffectInr {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
}

.table th,
.table td {
  padding: 8px;
}

// header-trk start
.header-contain {
  position: fixed;
  width: 100%;
  float: left;
  z-index: 999;
  align-items: center;

  .hdrTrk-logo {
    float: left;

    img {
      height: var(--brand-logo-nav-height);
      width: auto;
    }
  }

  .nav {
    &-link {
      color: #666;

      &.active {
        color: var(--color-main);
        font-weight: bold;
      }
    }
  }
}

.hdrTrk-nav {
  float: right;

  .contact-list-icon {
    height: 33px;
    width: 33px;
    font-size: 14px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 100px;

    .contact-list-icon-txt {
      position: relative;
      float: left;
      height: 100%;
      width: 100%;

      .contact-list-icon-img {
        height: 30px;
        width: 30px;
        float: left;
        overflow: hidden;
        border-radius: 50px;
        padding: 1px;
        position: absolute;
        left: 1px;
        top: 1px;

        span {
          background: var(--color-main);
          height: 100%;
          width: 100%;
          float: left;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 100px;
        }
      }
    }
  }
}

.nav-li.li-usr .droplist-scrollbar {
  top: 40px;
}

.notification {
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  top: 5px;
  right: 1px;
}

// header-trk end

// nav-wrp start
.nav-bar {
  float: left;
  width: 100%;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  &-logo {
    float: left;
    min-height: 35px;
    max-height: 35px;
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    img {
      width: auto;
      height: 35px;
      float: left;
      max-height: 35px;
    }
  }
}

.nav-custom {
  float: right;

  &-ul {
    float: right;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    .nav-li {
      float: left;
      margin: 0 0 0 16px;
      position: relative;
      list-style: none;
      -webkit-transition: all 200ms linear;
      -moz-transition: all 200ms linear;
      -ms-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;

      &-link {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        color: #333;
        position: relative;
        padding: 5px 0;
        text-decoration: none;
        font-weight: 400;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
      }

      .nav-li.active .nav-li-link {
        text-decoration: none;
        color: #025c99;
        font-weight: 600;
        display: inline;
      }

      .nav-li.active .nav-li-link p {
        text-decoration: none;
        color: #025c99;
        height: 2px;
        width: 33px;
        background: #025c99;
        position: relative;
        bottom: -3px;
        margin: 0 auto;
      }

      .nav-li:hover .nav-li-link {
        color: var(--color-main);
      }
    }
  }
}

.mob-btn {
  display: none;
}

.nav-li.li-usr .droplist-scrollbar {
  min-width: 160px;
}

.droplist-scrollbar {
  position: absolute !important;
  right: 0;
  top: 26px;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: auto;
  min-width: 110px;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #333;
  border-bottom: 10px solid #fff;
  z-index: 99;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -moz-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -ms-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -webkit-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  -ms-transform: translate3d(0, 10px, 0);
  -o-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.dropMenu {
  background: #fff;
  position: absolute;
  visibility: visible;
  opacity: 1;
  min-height: 50px;
  margin: -14px 0 0 0;
  min-width: 50px;
  max-width: 150px;
  display: block;
  padding: 0;
  font-size: 12px;
  color: #333;
  border-bottom: 10px solid #fff;
  z-index: 99;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -moz-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -ms-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -webkit-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  -ms-transform: translate3d(0, 10px, 0);
  -o-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.dropMenu .droplist-ul {
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
  position: relative;
  z-index: 9999;
}

.dropMenu .droplist-li {
  float: left;
  width: 100%;
}

.dropMenu .droplist-li:first-child .droplist-a {
  border-top: none;
}

.dropMenu .droplist-li .droplist-a {
  font-size: 13px;
  padding: 8px 0 8px 0;
  float: left;
  width: 100%;
  text-align: left;
  border-top: 1px solid #eee;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #333;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.dropMenu .droplist-li .droplist-a:hover {
  color: var(--color-main);
}

.dropMenu .droplist-li .droplist-a > i {
  padding: 0 6px 0 0;
}

.nav-li.show .droplist-scrollbar {
  opacity: 1;
  visibility: visible;
  height: auto;
  max-height: 70vh;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.droplist-ul {
  padding: 14px 15px 0 15px;
  list-style: none;
  float: left;
  width: 100%;
  position: relative;
  z-index: 9999;
}

.droplist-li {
  float: left;
  width: 100%;
}

.nav-li .droplist-li:first-child .droplist-a {
  border-top: none;
}

.nav-li .droplist-li .droplist-a {
  font-size: 13px;
  padding: 8px 0 8px 0;
  float: left;
  width: 100%;
  text-align: left;
  border-top: 1px solid #eee;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #333;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.nav-li .droplist-li .droplist-a:hover {
  color: var(--color-main);
}

.nav-li .droplist-li .droplist-a > i {
  padding: 0 6px 0 0;
}

.nav-scroll {
  padding: 5px 0;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.nav-custom-ul {
  .dropdown-item {
    min-width: 400px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 0;
    }

    .card {
      background-color: transparent;
      border-width: 0;
      border-radius: 0;

      &-body {
        white-space: normal;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
      }
    }
  }
}

.nav-custom-ul li::marker {
  content: none;
}

@media (max-width: 700px) {
  .mob .nav-bar {
    display: block;
    padding: 0;
  }

  .mob .nav-bar .droplist-scrollbar {
    right: auto;
    left: 0;
    top: 29px;
    width: 80%;
    margin: 5px 35px 0 35px;
  }

  .mob .nav-custom {
    width: 100%;
    float: left;
    position: fixed;
    background: #fff;
    top: 46px;
    left: 0;
    height: 100%;
    display: none;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .mob .nav-custom .nav-custom-ul {
    float: right;
    width: 100%;
    display: block;
    height: 100%;
  }

  .mob .nav-custom .nav-custom-ul .nav-li {
    margin: 0;
    float: left;
    width: 100%;
  }

  .mob .nav-custom .nav-custom-ul .nav-li .nav-li-link {
    text-align: left;
    padding: 11px 15px 11px 15px;
    border-bottom: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    position: relative;
  }

  .mob .nav-custom .nav-custom-ul .nav-li.active .nav-li-link p {
    position: absolute;
    margin: 0;
    left: 15px;
    bottom: 6px;
  }

  .mob-btn {
    display: block;
    position: absolute;
    right: 12px;
    top: 9px;
    cursor: pointer;
  }

  .mob-btn .tog-arrow1 {
    height: 2px;
    width: 25px;
    background: #666;
    margin: 0 0 5px 0;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .bt-x .tog-arrow1:last-child {
    height: 2px;
    opacity: 0;
    display: none;
    top: -13px;
    position: absolute;
    left: 0;
    cursor: pointer;
  }

  .bt-x .tog-arrow1:nth-child(1) {
    margin: -2px 0 0 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .bt-x .tog-arrow1:nth-child(2) {
    margin: -2px 0 0 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .bt-x {
    top: 18px;
    right: 10px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .mob-hidden .nav-custom {
    display: block;
    max-height: 100vh;
    overflow: auto;
    margin: 0 0 200px 0;
  }

  .mob-btn::after {
    content: "";
    height: 26px;
    width: 25px;
    position: fixed;
    cursor: pointer;
    top: 7px;
  }

  .filter-dropdown {
    width: 300px !important;
  }
}

@media (max-width: 568px) {
  .right-modal {
    width: 100% !important;
  }
}

@media (max-width: 430px) {
  .droplist-scrollbar.notificationList {
    right: -43px;
    width: 290px !important;
  }
}

// nav-wrp end

// bg color css start

.bg-darksteelblue {
  background: #abb0d6 !important;
  color: #fff;
}

.bg-thistle {
  background: #d0aece !important;
  color: #fff;
}

.bg-darkseagreen {
  background: #bfd699 !important;
  color: #fff;
}

.bg-darkkhaki {
  background: #ccca90 !important;
  color: #fff;
}

.bg-blueskydull {
  background: #5cb0ba !important;
  color: #fff;
}

.tx-dull {
  color: #ccc;
}

.tx-red {
  color: #f10c22;
}

.tx-white {
  color: #fff;
}

.tx-green {
  color: #07d11b;
}

.tx-orange {
  color: #ffeb00;
}

.tx-amber {
  color: #ffbf00;
}

// bg color css end

// table mx height
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  vertical-align: middle;
}

.mx-th-tag {
  // min-width: 80px;
  // max-width: 160px;
  position: relative;
}

.mx-th-tag:hover .mx-td-spn {
  max-height: 700px;
  white-space: normal;
  -webkit-transition: max-height 2s ease-in-out;
  -moz-transition: max-height 2s ease-in-out;
  -ms-transition: max-height 2s ease-in-out;
  -o-transition: max-height 2s ease-in-out;
  transition: max-height 2s ease-in-out;
}

.mx-td-spn {
  // max-height: 30px;
  max-height: 24px;
  text-overflow: ellipsis;
  max-width: 100%;
  min-width: 90px;
  white-space: nowrap;
  cursor: default;
  overflow: hidden;
  -webkit-transition: max-height 1.5s cubic-bezier(0, 1.05, 0, 1);
  -moz-transition: max-height 1.5s cubic-bezier(0, 1.05, 0, 1);
  -ms-transition: max-height 1.5s cubic-bezier(0, 1.05, 0, 1);
  -o-transition: max-height 1.5s cubic-bezier(0, 1.05, 0, 1);
  transition: max-height 1.5s cubic-bezier(0, 1.05, 0, 1);
}

table.dataTable thead th {
  border-top: 0;
  border-bottom: 0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px;
}

table {
  border: 1px solid #dee2e6;
}

// table mx height end

.user-img-name {
  display: flex;

  img,
  span {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin-right: 5px;
  }

  span {
    text-align: center;
    font-size: 13px;
    background-color: var(--color-main);
    line-height: 22px;
    color: $white;
    text-transform: uppercase;
  }
}

.moments-list {
  display: flex;
  align-items: center;

  span {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin-right: 5px;
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    color: #fff;
    text-transform: uppercase;

    &.positive {
      background-color: $success;
    }

    &.Neutral {
      background-color: $blue;
    }

    &.concern {
      background-color: $danger;
    }
  }
}

// table mx height end

.form-control.k-input {
  background-color: #fff;
}

.word-cloud {
  width: 100% !important;
  height: 300px !important;
  position: relative;
}

/*swthBTN*/
.swt-btn .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  border-radius: 0;
}

.swt-btn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.swt-btn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.swt-btn .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.swt-btn input:checked + .slider {
  background-color: #8183b9;
}

.swt-btn input:focus + .slider {
  box-shadow: 0 0 1px #357dad;
  -moz-box-shadow: 0 0 1px #357dad;
  -webkit-box-shadow: 0 0 1px #357dad;
}

.swt-btn input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.swt-btn .slider.round {
  border-radius: 34px;
}

.swt-btn .slider.round:before {
  border-radius: 50%;
}

.fltBoxRow-btn .swt-btn .slider:before {
  height: 16px;
  width: 16px;
}

.fltBoxRow-btn .swt-btn .switch {
  width: 40px;
  height: 24px;
}

/*swthBTN--end*/
.tx-link-button {
  cursor: pointer !important;
  color: var(--color-main);
  font-weight: bold;
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-auto {
  cursor: auto !important;
}

.min-h-auto {
  min-height: auto;
}

.nav .nav-item {
  position: relative;
}

.nav .nav-item .droplist-scrollbar {
  top: 36px;
}

.notificationList li {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 0 8px 0;
}

.notificationList ul {
  padding: 0;
}

.droplist-scrollbar.notificationList {
  top: 30px;
  max-height: initial !important;
  width: 320px;
}

.notificationList-t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.notificationTitle.fs-25 {
  padding: 0 4px 0 0;
  margin-bottom: 0;
}

.notificationVal {
  background: #e63f3f;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0 8px;
}

.notification-switch {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.notification-markallread,
.notification-markallread-loader {
  border-top: 1px solid rgb(215, 215, 215);
  padding: 0 15px;
  display: flex;
}

.notification-markallread p {
  margin: 0;
  cursor: pointer;
}

.notification-markallread-loader p {
  margin: 0;
  cursor: default;
  color: grey;
}

.notification-markallread p:hover {
  text-decoration: underline;
}

.notification-markallread-loader p:hover {
  text-decoration: none;
}

.notification-seemore,
.notification-seemore-loader {
  border: 1px solid white;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notification-seemore p,
.notification-seemore-loader p {
  font-size: 15px;
  margin: 0;
  border-radius: 5px;
  padding: 0 5px;
}

.notification-seemore p:hover {
  background-color: rgb(229, 229, 229);
  cursor: pointer;
}

.notification-seemore-loader p {
  background-color: rgb(241, 241, 241);
  color: grey;
  cursor: default;
}

.note-top-div {
  justify-content: space-between;
}

.not-h {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  padding: 0 0 5px 0;
  line-height: 16px;
}

.not-time {
  float: right;
  min-width: 60px;
  text-align: right;
  color: #a9a9a9;
}

.not-time {
  float: right;
  min-width: 60px;
  text-align: right;
  color: #a9a9a9;
  font-size: 11px;
  line-height: 14px;
}

.note-p {
  font-size: 12px;
  color: #333;
  line-height: 16px;
}

.noteIcn {
  width: 34px;
  height: 30px;
  float: left;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin: 0 15px 0 0;
}

// sideBar
.sideBar {
  height: 100%;
  width: 20rem;
  position: fixed;
  top: 0;
  right: -25rem;
  box-shadow: 1px 2px 15px 1px rgba(0, 0, 0, 0.15);
  transition: all 300ms linear;
  z-index: 9999;
}

.sideBar-overlap.active {
  right: 0;
  transition: all 300ms linear;
}

.paneInner {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.paneBtn {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px 0 10px 0;
}

.paneBtnInner {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  font-family: "Open Sans";
}

.closePane {
  float: left;
  position: absolute;
  right: 10px;
  top: 10px;
}

.pane-h {
  float: left;
  width: 100%;
  padding: 8px 30px 10px 12px;
  font-size: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  font-weight: 600;
}

.paneContainer {
  float: left;
  width: 100%;
  padding: 10px 10px 10px 15px;
}

// sideBar
.version-histoy {
  float: left;
  width: 100%;
  position: relative;
  max-height: 85vh;
  overflow-x: auto;
}

.histoyTrk {
  float: left;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 5px 0 15px 0;
}

.histoyTrkTime {
  float: left;
  width: 100%;
  font-size: 14px;
  color: #333;
  padding: 0 0 2px 0;
}

.histoyTrkUser {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}

.histoyTrkUser-i {
  margin: 0 10px 0 0;
  color: #fff;
  font-size: 16px;
  background: #b3b3b3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 50px;
}

.histoyTrkUser-p {
  color: #999;
}

.line-height-1 {
  line-height: 0.9375rem;
}

.line-height-2 {
  line-height: 1.125rem;
}

// tblUsr
.tblUsr.moreUsr {
  float: left;
  padding: 0 0 0 8px;
}

.tblUsr.moreUsr .topUsrAreaPic {
  margin: 0 0 0 -8px;
}

.tblUsr .topUsrAreaPic {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.tblUsr.moreUsr .topUsrAreaPic-i {
  padding: 0;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.topUsrAreaPic-i {
  float: left;
  padding: 0 5px 0 0;
}

.topUsrAreaPic-tx {
  display: grid;
}

.tblUsr .contact-list-icon {
  position: relative;
  text-transform: uppercase;
  width: 120px;
  height: 120px;
  margin: 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 120px;
  -moz-border-radius: 120px;
  -ms-border-radius: 120px;
  border-radius: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.tblUsr .topUsrAreaPic-i .contact-list-icon {
  border: 2px solid rgba(255, 255, 255, 1);
  // background: #a1a2ca;
  // cursor: pointer;
}

.tblUsr .contact-list-icon {
  width: 38px;
  height: 38px;
}

.tblUsr .topUsrAreaPic-i .contact-list-icon .contact-list-icon-txt {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.tblUsr .contact-list-icon-img {
  height: 100%;
  width: 100%;
  float: left;
  overflow: hidden;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.tblUsr .contact-list-icon-img img {
  width: 100%;
  font-size: 12px;
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
}

.tblUsr .topUsrAreaPic.nav-li.subCenter {
  position: relative;
  z-index: 9;
}

.tblUsr .nav-li.subCenter.show .droplistOuter {
  opacity: 1;
  top: 0;
  max-height: 70vh;
}

.tblUsr .droplistOuter {
  float: left;
  width: 100%;
  height: 0;
  position: relative;
  opacity: 0;
  top: 15px;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

tr:last-child .tblUsr .nav-li.subCenter.show .droplist-scrollbar {
  top: -13px !important;
}

.tblUsr .nav-li.subCenter .droplist-scrollbar {
  transform: translate(-50%, 0);
  left: 50%;
  right: 50%;
  top: 0;
  position: absolute;
  display: none;
}

.tblUsr .nav-li.show .droplist-scrollbar {
  min-width: 130px;
  padding: 8px 0 4px 0;
  top: 25px !important;
  left: 37px !important;
  opacity: 1;
  visibility: visible;
  height: auto;
  max-height: 70vh;
  display: block;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.tblUsr .droplist-scrollbar {
  box-shadow: 0 2px 8px 0 rgba(138, 155, 165, 0.5);
}

.tblUsr .droplist-ul {
  bottom: 0;
  position: absolute;
  border-bottom: 10px solid #fff;
  background: #fff;
  z-index: 99;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -moz-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -ms-box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  box-shadow: 0 5px 14px 0 rgba(138, 155, 165, 0.25);
  -webkit-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  -ms-transform: translate3d(0, 10px, 0);
  -o-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.tblUsr.moreUsr .topUsrAreaPic-i:hover {
  z-index: 99;
  // transform: scale(1.3);
}

.tblUsr.moreUsr .topUsrAreaPic.zoomPic .topUsrAreaPic-i:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 99;
}

// tblUsr end
/*tabSetting*/
.right-modal {
  transform: translate(-50px, 0) !important;
  transition: all 200ms linear;
  width: 410px;
}

.right-modal.show {
  transform: translate(0, 0) !important;
}

.left-modal {
  transform: translate(-50px, 0) !important;
  transition: all 200ms linear;
  width: 410px;
}

.left-modal.show {
  transform: translate(0, 0) !important;
}

.flt-box .filter-data {
  float: left;
  width: 100%;
}

.fltBox {
  max-height: 78vh;
}

.fltBoxTrk {
  float: left;
  width: 100%;
}

.fltBoxRow {
  float: left;
  width: 100%;
  padding: 2px 0 3px 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.fltBoxRow-txt {
  font-size: 14px;
  font-weight: 600;
}

.fltBoxList {
  float: left;
  width: 100%;
  padding: 0 0 5px 0;
}

.setDiv {
  float: left;
  width: 100%;
}

.setTabTrkRow {
  float: left;
  width: 100%;
  padding: 0 15px 0 0;
}

.setTabTrk {
  min-height: 32px;
  min-width: 100px;
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
  margin: 0 12px 10px 0;
}

.setTabTrk {
  min-height: 32px;
  min-width: 100px;
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.setTabTrk input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  margin: 0;
  z-index: 9;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.setTabTrkRow .setTabTrk .setTabTrk-a {
  padding: 2px 10px 2px 10px;
  position: relative;
}

.setTabTrk .setTabTrk-a {
  position: absolute;
  text-decoration: none;
  top: 0;
  left: 0;
  z-index: 8;
  min-height: 100%;
  padding: 0;
  cursor: pointer;
  background-color: white;
  color: #333333;
  margin-bottom: 0;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  text-align: center;
  width: 100%;
  border-radius: 50px;
  border: 2px solid rgba(98, 100, 167, 0.2);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.05);
}

.setTabTrk .setTabTrk-a .spnTx {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}

.setTabTrk.checked .setTabTrk-a {
  background: #8183b9;
  border: 2px solid #8183b9;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
}

.setTabTrk.checked .setTabTrk-a .spnTx {
  color: #fff;
}

.set-tab {
  float: left;
  width: 100%;
}

.setDivRow {
  float: left;
  width: 97%;
  padding: 0 0 20px 0;
  border-bottom: 2px solid rgba(98, 100, 167, 0.05);
  margin: 0 0 20px 0;
}

.setDivRow:last-child {
  border: none;
}

.set-t {
  float: left;
  width: 100%;
  padding: 0 5px 2px 0;
  margin: 0 0 12px 0;
  font-size: 16px;
  color: rgba(98, 100, 167, 1);
  font-weight: 600;
}

.setSubData {
  float: left;
  width: 100%;
  padding: 3px 0 0 0;
}

.setSubTrk {
  float: left;
  width: 100%;
}

.setSubTrk .form-group {
  float: left;
  width: 100%;
  padding: 0 0 5px 0;
  margin: 0;
}

.setSubTrk .frm_fld_lbl {
  padding: 0 10px 0 0;
  margin: 0;
  font-weight: 600;
}

.setSubTrk .k-widget.k-dropdown {
  float: left;
  width: 100%;
}

.setSubTrk .u-activity-hdr .li-globalFilter .k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-multiselect-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-selectbox .k-input,
.k-textbox > input {
  height: 26px;
  padding: 0 0 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.setSubTrk .form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group input[type="date"],
.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group input[type="search"],
.form-group textarea,
.form-group .form-control {
  min-height: 26px;
  height: 26px;
}

.k-dropdown-wrap .k-select,
.k-numeric-wrap .k-select,
.k-picker-wrap .k-select {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.filterPushDivInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterPushDivInner .expandRowHdrIcon {
  right: auto;
  top: 0;
  position: relative;
  text-align: right;
}

.filterHeaderDiv {
  display: flex;
  align-items: center;
}

.filterHeaderDiv .panelTitleBadge {
  line-height: 22px;
  display: flex;
  margin: 2px 0 3px 0;
  word-wrap: break-word;
  word-break: break-all;
  width: 100%;
}

.rightBox .filterExpd .rangeFld {
  flex-wrap: nowrap;
  justify-content: start;
}

.rightBox .filterExpd .rangeFldTrk {
  width: 100%;
  // padding-right: 10px !important;
}

.rangeFld {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.rangeFldTrk {
  float: left;
  width: 100%;
  padding: 0 10px 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.rangeInput {
  width: 100%;
  float: left;
}

.rangeFldTrk-h {
  padding: 0 5px 0 0;
  font-size: 12px;
}

.k-autocomplete,
.k-dropdown-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-picker-wrap.k-state-default {
  background-color: #fff !important;
  border-radius: 3px;
}

.filter-data .k-autocomplete,
.filter-data .k-dropdown-wrap.k-state-default,
.filter-data .k-numeric-wrap.k-state-default,
.filter-data .k-picker-wrap.k-state-default {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group input[type="date"],
.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group input[type="search"],
.form-group textarea,
.form-group .form-control {
  border-radius: 3px;
  height: auto;
  min-height: 30px;
  font-size: 13px;
}

.k-combobox,
.k-combobox .k-input,
.k-datepicker,
.k-datepicker .k-input,
.k-datetimepicker,
.k-datetimepicker .k-input,
.k-timepicker,
.k-timepicker .k-input {
  background-color: #fff !important;
}

.setDiv .k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input {
  display: flex;
}

.fltBoxList.show .fltBoxRowInrData {
  display: block !important;
}

.fltAcn .srt1 {
  float: left;
  width: 100%;
  padding: 6px 10px 7px 10px;
  background: rgba(0, 0, 0, 0.01);
  margin-bottom: 10px;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

/*tabSetting--end--*/
.history {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  border: none;
}

.history .k-chart {
  height: 58px;
}

.history td.chart {
  padding-right: 20px;
}

.history td.item {
  line-height: 60px;
  width: 20px;
  text-align: right;
  padding-bottom: 22px;
}

.scrLabel {
  float: left;
  line-height: 18px;
}

// table css start
.min-w-160 {
  min-width: 160px;
}

.min-w-200 {
  min-width: 200px;
}

// table css end
body {
  line-height: 1.25rem;
  font-family: "Poppins" !important;
  color: #333;
  font-size: 0.875rem;
}

html,
a,
.k-tabstrip {
  font-family: "Poppins" !important;
}

.dialogSide .k-dialog {
  font-family: "Poppins" !important;
}

.font-weight-semi {
  font-weight: 500 !important;
}

// scrollbar webkit start
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.07);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.17);
  border-radius: 20px;
}

// scrollbar webkit end
// scrollbar firefox start
.scroller {
  overflow-y: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.07);
  scrollbar-width: thin;
}

// scrollbar firefox start
.justify-content-space-between {
  justify-content: space-between;
}

body {
  background-color: rgba(0, 0, 0, 0.03);
}

.tab-textTransformNone .k-tabstrip-items {
  text-transform: none;
}

.tab-textTransformNone {
  text-transform: none;
}

.hoverTextFull:hover .viewFullWordHover {
  word-break: break-all;
  white-space: break-spaces;
  // line-height: 16px;
  transition: 0.3s;
  max-height: 100px;
}

.boxLabel {
  //background: rgba($primary, 0.25);
  background: var(--color-main);
  opacity: 0.25;
  float: left;
  width: 100%;
  margin: 0 12px 12px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  font-size: 14px;
  padding: 0 5px;
}

.boxLabelAI {
  //background: rgba($primary, 0.7);
  background: var(--color-main);
  opacity: 0.7;
  margin: 0 0 0 3px;
  padding: 0 5px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
}

.formAdd {
  min-width: 300px;
  width: 420px;
  max-height: 68vh;
  overflow-y: auto;
}

.tabWithIcon .k-tabstrip-items-wrapper .k-item::after {
  z-index: 9;
  right: 7px;
  top: 7px;
  position: absolute;
  left: auto;
  content: "\f4cb";
  font-family: "bootstrap-icons";
  cursor: pointer;
}

.labelListing {
  font-family: "Poppins" !important;
  padding: 10px 15px 15px 15px;
}

.kPopNone .k-popup.popup-content.k-child-animation-container {
  background: transparent;
}

.kPopNone {
  box-shadow: none !important;
}

.TemplateMessageOuter {
  float: left;
  width: 100%;
  height: 100%;
  background: #fff;
}

.TemplateMessageInner {
  width: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
  float: left;
  background-color: transparent;
  color: #333;
  padding: 25px;
  border-radius: 4px;
}

.TemplateMessageBox {
  text-align: center;
  float: left;
  width: 100%;
}

.k-rounded-4 {
  border-radius: 4px !important;
}

.listViewd {
  background: rgb(236, 245, 254);
}

.viewMoreMobileData {
  padding: 6px 5px 5px 0px;
}

.viewMoreData {
  padding: 6px 5px 5px;
}

.viewMoreDataInr {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 6px 10px 10px;
}

.userListingDataCol {
  background: rgba(0, 0, 0, 0.03);
  float: left;
  padding: 7px 15px 10px 15px;
  margin: 7px 0 7px 0;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.userListingData {
  padding: 8px 5px 8px 5px;
  max-height: 80vh;
  overflow: hidden auto;
  min-width: 720px;
}

.liChecked {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.03);
}

.liUnChecked {
  opacity: 0.7;
}

.langLabel {
  position: absolute;
  top: 10px;
  right: 7px;
}

.detailTopic .mx-td-spn .keywordTrk {
  margin: 2px 8px 7px 0;
}

.text-primary {
  color: var(--color-main) !important;
}

.k-loader-primary {
  color: var(--color-main) !important;
}

.bg-primary {
  background-color: var(--color-main) !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: var(--color-main) !important;
}

.k-pager-numbers .k-link.k-selected {
  color: var(--color-main) !important;
  background-color: var(--color-page-selected) !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: var(--color-main) !important;
}

.UserBoxLabel {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
  padding: 9px 0 9px 0;
}

.UserBoxLabel:last-child {
  border-bottom: none;
  padding: 0;
}

.numCount {
  padding: 0px 4px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 2px;
  margin-top: 4px;
  margin-bottom: 3px;
  cursor: pointer;
}

.transparentBtnHover .k-button:hover::before,
.k-button.k-hover::before {
  opacity: 0;
}

.archiveRow td {
  opacity: 0.5;
}

.archiveRow td:last-child {
  opacity: 1;
}

.archiveRow td:last-child .iconBtnVisible {
  opacity: 1;
}

.archiveRow td:last-child .iconBtDisable {
  opacity: 0.5;
}

.blr-msg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.blr-msg .blurMsgInr {
  background: rgba(255, 255, 255, 0.9);
  padding: 4px 20px;
  margin: 0;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.15);
}

.blurArea1 {
  filter: blur(1px);
  opacity: 5;
}

.blurArea2 {
  filter: blur(2px);
}

.blurArea3 {
  // filter: blur(3px);
  color: rgba(0, 0, 0, 0.16) !important;
}
.blurArea3 i {
  color: rgba(0, 0, 0, 0.1) !important;
}
.blurArea3 ul li span {
  background: rgba(0, 0, 0, 0.1) !important;
}
.blurArea3 .track-single .trackCursor {
  background: rgba(0, 0, 0, 0.01) !important;
}
.blurArea4 {
  filter: blur(1.5px);
  opacity: 2;
}
col.k-sorted,
th.k-sorted {
  background-color: rgba(0, 0, 0, 0.015);
}
.boxLabOuter {
  max-width: 860px;
}
.k-dialog {
  font-family: "Poppins" !important;
}

.footerContent {
  position: fixed;
  bottom: 0px;
  margin-right: auto;
  margin-left: auto;
  z-index: 99;
}

.mBottom70 {
  margin-bottom: 70px;
}

.trk-t {
  float: left;
  width: 100%;
  font-size: 15px;
  padding: 0 0 7px 0;
  margin: 0;
  font-weight: 500;
}

.border-none {
  border: 0 !important;
}

.userListGroup .k-panelbar-group.k-panel.k-group {
  padding: 0 10px 0 15px;
  background: #fff;
}

.userListGroup .k-panelbar-header {
  margin: 2px 0 4px 0;
  float: left;
  width: 100%;
  border-top: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.userListGroup .k-panelbar-header.k-expanded .k-link {
  background: #fff;
}

.userListGroup .k-panelbar-header .k-link {
  font-weight: 500;
  font-family: "Poppins" !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 10px 8px 15px;
}

.activeBox {
  // transform: scale(1.1);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}

.activeBox {
  // transform: scale(1.1);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}

.activeBox {
  transform: scale(1.02);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid #444;
  transition: all 0.5s ease-out;
  border-radius: 4px;
  border: 1px solid rgba(var(--color-main-rgb), 0.6);
}

.input-search {
  border: 1px solid rgb(206, 206, 206);
  outline: none;
  border-radius: 3px;
  padding-left: 15px;
  background: transparent;
  width: 200px;
  height: 27px;
  font-size: 12px;
}

.mainBox .rightBox {
  width: 100%;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mainBox .detailBox {
  width: 100%;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mainBox .k-animation-container.k-animation-container-relative {
  z-index: 0;
}

.iconRow {
  flex-wrap: wrap;
}

.kpiList {
  display: flex;
  justify-content: space-around;
  width: 100%;
  float: left;
  padding: 0;
  flex-wrap: wrap;
}

.kpiList-a {
  text-align: center;
  min-width: 100px;
  max-width: 100px;
  float: left;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  position: relative !important;
  transition-duration: 0.3s;
  transition-property: transform;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.kpiList-a:hover {
  text-decoration: none;
  border: 1px solid #ccc;
  transform: translateY(-5px);
}

.kpiList-a:hover .kpiListval-bdr::before {
  transform: translateY(5px);
  opacity: 1;
}

.kpiList-a.bdr-purpleBlue:hover {
  border-color: #888ac1 !important;
}

.kpiList-a.bdr-success:hover {
  border-color: #28a745 !important;
}

.kpiList-a.bdr-warning:hover {
  border-color: #ffc107 !important;
}

.kpiList-a.bdr-danger:hover {
  border-color: #dc3545 !important;
}

.kpiList-a.bdr-skyBlue:hover {
  border-color: #50a0e4 !important;
}

.kpiList-a.bdr-purpleLight:hover {
  border-color: #e178b2 !important;
}

.kpiList-a.bdr-purpleBlue.active {
  border-color: #888ac1 !important;
}

.kpiList-a.bdr-success.active {
  border-color: #28a745 !important;
}

.kpiList-a.bdr-warning.active {
  border-color: #ffc107 !important;
}

.kpiList-a.bdr-danger.active {
  border-color: #dc3545 !important;
}

.kpiList-a.bdr-skyBlue.active {
  border-color: #50a0e4 !important;
}

.kpiList-a.bdr-purpleLight.active {
  border-color: #e178b2 !important;
}

.kpiList-a.active {
  text-decoration: none;
  border: 1px solid #ccc;
  transform: translateY(-5px);
}

.kpiList-a.active .kpiListval-bdr::before {
  transform: translateY(5px);
  opacity: 1;
}

.kpiListval-bdr {
  float: left;
  width: 100%;
  height: 3px;
  background: #ddd;
  // border-radius: 0 0 20px 20px;
}

.kpiListval-bdr::before {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  z-index: 1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  transition-duration: 0.3s;
  transition-property: transform, opacity;
}

.kpiListval {
  float: left;
  width: 100%;
}

.kpiListval-v {
  padding: 5px 0;
  color: rgba(0, 0, 0, 0.7);
}

.kpiListTile {
  color: rgba(0, 0, 0, 0.55);
  font-weight: 500;
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 28%;
  align-items: baseline;
}

.kpiListTile-i {
  float: left;
}

.kpiListTile-tx {
  float: left;
  max-width: 100%;
  line-height: 14px;
}

div,
button,
a {
  font-family: "Poppins" !important;
}

.inrExpand {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.inrExpand .k-panelbar {
  border-color: rgba(0, 0, 0, 0.1);
}

.expandRowHdrIcon {
  position: absolute;
  right: 10px;
  top: 2px;
}

.arrowIconHidden .k-icon {
  display: none;
}

.labelListing .labelLiRow-p {
  line-height: 24px;
}

.iconRow .iconCol {
  padding: 2px 0 2px 0;
}

// docSpliter
.docSpliter .k-widget.k-splitter {
  border: none;
  background: none;
}

.docSpliter .k-splitbar.k-splitbar-horizontal {
  padding: 0;
  margin: 0 0 0 5px;
  width: 2px;
  min-height: 87vh;
  z-index: 2;
}

.docSpliter .k-splitbar-horizontal .k-collapse-prev {
  margin-bottom: 7px;
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
    rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
}

.docSpliter .k-ghost-splitbar .k-icon,
.k-splitbar .k-icon {
  font-size: 15px;
}

.docSpliter .k-splitter .k-scrollable {
  overflow: unset;
  z-index: 1;
}

.mainBox .iconCol-i {
  display: flex;
  align-items: center;
  justify-self: center;
}

.docSpliter .k-splitter-flex .k-pane-static {
  padding-right: 10px;
}

.mainBox .iconCol i {
  line-height: 0;
}

.mainBox .iconCol .iconCol-val {
  line-height: 0;
}

.mainBox .k-panelbar {
  border-radius: 0.25rem !important;
  border: none;
}

.docSpliter .k-icon:hover,
.k-icon:focus {
  color: #333 !important;
}

.docSpliter .k-splitbar:focus,
.k-splitbar.k-focus {
  color: #333 !important;
  background: rgba(var(--color-main-rgb), 0.15);
}

// filterBox
.callListInner {
  display: flex;
  overflow: hidden;
}

.callListBox {
  width: 100%;
  overflow: hidden;
}

.callListBoxFilter {
  width: 400px;
  min-width: 400px;
  margin: 0 0 0 15px;
  background: #fff;
}

.btnGroupData {
  z-index: 1;
}

.rightBox .expandBox .k-panelbar-item-text {
  word-break: break-all;
  width: 99%;
}

.arrowClockwiseBtn {
  position: relative;
}

.arrowClockwiseBtn i {
  width: 100%;
  height: 33px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  transition: all 200ms linear;
}

.arrowClockwiseVal {
  height: 33px;
  width: 100%;
  float: left;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
}

.btnClockwise:active i.bi.bi-arrow-clockwise {
  transform: rotate(60deg);
  transition: all 200ms linear;
}

.btnCounterclockwise:active i.bi.bi-arrow-counterclockwise {
  transform: rotate(-60deg);
  transition: all 200ms linear;
}

.extraConrollBtn {
  display: flex;
  align-items: center;
}

.extraBtnLabel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 125px;
  float: right;
  margin: 5px 15px 0 0;
}

.extraBtnLabel .lblChecks {
  margin: 0 2px 0 0;
}

.mergeUserIcn {
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mergeUserIcn .icnUser-i {
  position: absolute;
  margin-right: 0;
}

.mergeUserIcn .icnWifi-i {
  position: absolute;
  margin: -6px 0 0 16px;
  transform: rotate(90deg);
}

.k-treeview {
  height: 180px;
}

.remove-cross .k-clear-value {
  visibility: hidden;
  pointer-events: none;
}

.remove-upperlower-spinner input::-webkit-outer-spin-button,
.remove-upperlower-spinner input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.searchDiv .k-clear-value {
  visibility: hidden;
  pointer-events: none;
}

.searchBox {
  position: relative;
}

.searchBox .searchIconBtn {
  position: absolute;
  right: 7px;
  top: 4px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.3);
}

.searchIcon .k-input {
  padding: 8px 30px 8px 16px;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.cardLabRow ul.k-tabstrip-items.k-reset {
  padding-left: 175px;
}

.navSidePro ul.k-tabstrip-items.k-reset {
  padding-left: 0;
}

.cardLabRow .detailColBox {
  position: relative;
  top: -45px;
}

.cardLabRow .tabLeftPadding-0 .k-tabstrip-items .k-item:first-child .k-link {
  padding: 10px !important;
}

.navSidePro {
  min-width: 230px;
}

.titleWithTab .leftProControl {
  position: relative;
  z-index: 4;
}

.navSidePro .k-tabstrip-left > .k-tabstrip-items-wrapper {
  min-width: 160px;
}

.navSidePro .k-tabstrip-left .k-tabstrip-items .k-link {
  justify-content: flex-start;
  padding: 14px 15px 14px 10px !important;
}

.tab-line
  .navSidePro
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item.k-active
  .k-link {
  background-color: rgba(var(--color-main-rgb), 0.1);
}

.tab-line .navSidePro .k-tabstrip-items-wrapper .k-item.k-active::after {
  border-bottom: 0;
}

.addNotificationForm .k-chip-md {
  padding: 3px 2px 2px 1px;
}
.addNotificationForm .k-chip-list {
  padding: 3px 4px 4px 5px !important;
}
.addNotificationForm .k-chip-label {
  font-size: 13px;
  line-height: 15px;
}
.addNotificationForm .k-chip-list .k-input-inner {
  height: 24px !important;
}
.addNotificationForm input.k-input,
textarea.k-textarea {
  padding: 8px 7px;
}
.boxLab {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.boxLab {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 15px 18px 15px;
  border-left: 3px solid var(--color-main);
  height: 100%;
  position: relative;
}
.chipBoxLbl {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 3px 0 0;
}
.optionSelectBox {
  padding: 3px 0 0 0;
}
.labInputLbl {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.8);
}
.labSubHeading {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 18px;
  padding: 0 0 10px 0;
}
.labHeading {
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  padding: 0 0 3px 0;
}
.labInputBox {
  padding: 0 0 10px 0;
}
.chipBoxBtn .k-button-md {
  padding: 4px 4px 2px 4px;
}
.chipBoxBtn.evlBoxBtn .k-button-md {
  font-size: 12px;
}
.chipBoxBtn .k-button.k-selected::before {
  opacity: 1;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19);
  background: transparent;
  border-bottom: 2px solid var(--color-main);
}
.chipBoxBtn .k-button.k-selected {
  margin: 0 2px 0 2px;
}
.chipBoxBtn .k-button .k-button-text {
  opacity: 0.8;
}
.chipBoxBtn .k-button.k-selected .k-button-text {
  opacity: 1;
}
.evlBoxBtn .k-button.k-selected {
  padding-bottom: 3px;
}

.k-calendar-container.k-group {
  z-index: 99999 !important;
}
.docSpliter .k-collapse-prev .k-icon-xs {
  font-size: 13px;
}
input.k-input,
textarea.k-textarea {
  padding: 3px 6px;
}
.cardListBlock .keywordTrk {
  word-break: break-all;
}
.systemSet .trk-container .trk-inr .trk-row .trkCol .trkCol-p {
  line-height: 20px;
}
.systemSet .trk-container .trk-inr .trk-row .trkCol .trkCol-h {
  line-height: 20px;
}
.systemSet .trk-container .trk-inr .trk-row .trkCol .trkCol-dot {
  line-height: 20px;
}

.k-animation-container.k-animation-container-fixed.k-animation-container-shown {
  z-index: 99999 !important;
}

.labelListing.labelLiFix .keyWordRight .keywordTrk {
  margin: 0 0 6px 4px;
}

.topicLblRight {
  min-width: 100px;
}

.topicLblLeft {
  width: 65%;
}

.labelLiParaTxtOuter {
  display: flex;
}
.labelLiParaTxt {
  word-break: break-all;
}

.playSwitchBt .k-switch-on .k-switch-track::after {
  content: "\f4f4";
  color: var(--color-main);
  height: 10px;
  width: 10px;
  font-family: "bootstrap-icons";
  position: absolute;
  top: 4px;
  right: 7px;
  z-index: 2;
  font-size: 15px;
}

.playSwitchBt .k-switch-off .k-switch-track::after {
  content: "\F4C3";
  color: #d4d4d4;
  height: 10px;
  width: 10px;
  font-family: "bootstrap-icons";
  position: absolute;
  top: 4px;
  left: 3px;
  z-index: 2;
  font-size: 15px;
}
// gridTable
.listCalls .k-grid table colgroup col:nth-child(2) {
  width: 60px;
}
.listCalls .k-grid table colgroup col:nth-child(3) {
  width: 115px;
}
.listCalls .k-grid table colgroup col:nth-child(4) {
  width: 220px;
}
.listCalls .k-grid table colgroup col:nth-child(5) {
  width: 40px;
}
.listCalls .k-grid table colgroup col:nth-child(6) {
  width: 160px;
}
.listCalls .k-grid table colgroup col:nth-child(7) {
  width: 80px;
}
.listCalls .k-grid table colgroup col:nth-child(8) {
  width: 110px;
}
.listCalls .k-grid table colgroup col:nth-child(9) {
  width: 120px;
}
.listCalls .k-grid table colgroup col:nth-child(10) {
  width: 50px;
}
.listCalls .k-grid table colgroup col:nth-child(11) {
  width: 120px;
}
.listCalls .k-grid table colgroup col:nth-child(12) {
  width: 120px;
}
.listCalls .k-grid table colgroup col:nth-child(13) {
  width: 120px;
}
.listCalls .k-grid table colgroup col:nth-child(14) {
  width: 120px;
}
// callListHome
.detailBox .listCalls .k-grid table colgroup col:nth-child(4) {
  width: 190px;
}
.detailBox .listCalls .k-grid table colgroup col:nth-child(9) {
  width: 120px;
}

.trRowData {
  word-break: break-all;
}
.storageChartDonut.topPush20 .k-chart {
  top: -35px;
}
.regionBtn {
  position: absolute;
  right: -1px;
  top: -20px;
  z-index: 999;
  display: flex;
}
.recordingRegionBtn {
  cursor: pointer;
}
.recordingRegionBtn i::before {
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
}
.waveRow wave canvas {
  height: 70% !important;
  margin-top: 20px;
}
.waveRow region.wavesurfer-region {
  height: 70% !important;
  margin-top: 20px;
}

.multiselectArrowIcon .k-form-field-wrap .k-multiselect::before {
  content: "\f229";
  font-family: "bootstrap-icons";
  position: absolute;
  margin-top: 1px;
  cursor: pointer;
  z-index: 9;
  font-size: 11px;
  transform: translate(-50%, -50%);
  right: 0;
  top: 50%;
}

.multiselectArrowIcon span.k-clear-value {
  right: 10px;
  position: relative;
}
// callLegs
.callLegs {
  float: left;
  width: 100%;
  padding: 6px;
}
.callLegs .kpi-left-info {
  width: 50%;
  margin-right: 7px;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  padding: 10px 10px;
  margin-bottom: 13px;
  background: #fff;
}
.callLegs .kpi-right-info {
  width: 50%;
  margin-left: 8px;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  padding: 10px 10px;
  margin-bottom: 13px;
  background: #fff;
}
.callLegs .kpi-row-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // font-weight: 500;
}
.callLegs .kpi-value label {
  margin: 0;
}
.callLegs .kpi-text {
  font-size: 13px;
  font-weight: bold;
}
.callLegs .kpi-value-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kpiBody {
  padding: 11px 10px 0 10px;
}
.callLegs .k-grid-content.k-virtual-content {
  overflow-y: auto;
}
.kpiListTr {
  float: left;
  width: 100%;
}
.kpiListTr .kpiList-a {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  min-width: 100%;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 3px 10px 3px 10px;
}
.kpiListTr .kpiListTile {
  align-items: center;
  justify-content: start;
  width: auto;
  min-width: 70px;
}
.kpiBdrLeft {
  width: 3px;
  height: 22px;
  margin: 0 10px 0 0;
}
.kpiScoreAvg {
  float: right;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  width: 75px;
}
.kpiScoreAvg-p {
  line-height: 14px;
  float: right;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  padding: 0 0 0 5px;
  width: 100%;
}
.kpiListTr .kpiListval {
  width: auto;
  line-height: 17px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 60px;
}
.kpiScoreAvg-h {
  float: right;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  font-size: 12px;
  width: 100%;
}
.kpiListTr .kpiListTile-tx {
  padding: 0 3px 0 0;
  // min-width: 58px;
  text-align: left;
}
.kpiListTr .kpiList-a.active {
  text-decoration: none;
  border: 1px solid #ccc;
  transform: translateY(0px);
}
.kpiListTr .kpiList-a:hover {
  text-decoration: none;
  border: 1px solid #ccc;
  transform: translateY(0px);
}

.hoverBlack .mx-td-spn:hover {
  color: black !important;
  cursor: pointer;
}
.trk-container.customerViewBox .trk-inr .trk-row .trkCol .trkCol-h {
  min-width: 250px;
  max-width: 250px;
}
.codeData {
  background: rgba(0, 0, 0, 0.02);
  padding: 15px 15px 1px 15px;
  color: #4f596c;
  font-style: italic;
  margin: 7px 0 10px 0;
  border-radius: 5px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
  float: left;
  width: 99.5%;
  position: relative;
}
.copCodeDataBtn {
  position: absolute;
  right: 30px;
  top: 11px;
  font-style: normal;
  font-size: 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 3px 4px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  background: #fff;
  z-index: 9;
}
.dropBox {
  display: flex;
  align-items: center;
}
.dropBoxInput .k-chip-md {
  padding: 1px 2px;
  font-size: 13px;
  line-height: 1.2857142857;
}
.dropBoxH {
  font-size: 13px;
  font-weight: 500;
}
.topCodeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 0 3px 0;
}
.inHeader .header-contain {
  top: 0;
  left: 0;
}
.hideCollapseBtn.buttons-container {
  display: none !important;
}
.tabLeftView .k-panelbar-content.k-content {
  padding: 5px 10px 15px 15px !important;
}
.tabLeftView .k-panelbar-item-text {
  font-weight: 500;
  color: #000;
  font-size: 14px;
  font-family: "Poppins" !important;
}
.tabLeftView .k-button {
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.trkCol-p.textAreaAuto .k-input-inner {
  height: auto;
}
.moreData {
  display: flex;
  align-items: center;
}
.uLiBox {
  float: left;
  width: 100%;
}
.uLiBoxRow {
  float: left;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 0 10px 0;
}
.iBtnDisable .k-button-solid-base:disabled,
.k-button-solid-base.k-disabled {
  border-color: none;
  background-color: rgba(var(--color-main-rgb), 0.08);
}
.dialogRow {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.dialogCol {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  padding: 6px 10px 4px 10px;
  border-radius: 4px;
}
.dialogCol.colOptions {
  width: 220px;
}
.dialogCol.colUsers {
  width: 310px;
}
.dialogColTitle {
  font-size: 15px;
  font-weight: bold;
}
.dialogCol.colUsers li.k-item {
  margin: 5px 15px -2px 0 !important;
  padding: 0 1px 6px 1px !important;
}
.dialogCol.colUsers li.k-item .k-link {
  padding: 0 !important;
}
.shareOptionsGroup {
  display: flex;
  padding: 4px 3px 5px 7px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  float: left;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.shareOptionsRow {
  float: left;
  width: 100%;
  margin: 0 0 5px 0;
}
.shareOptionsInput {
  padding: 0 6px 0 0;
}
.shareOptionsLabel {
  color: #000;
}
.dialogColInner {
  float: left;
  width: 100%;
  padding: 2px 0 0 0;
}
.shareOptions {
  float: left;
  width: 100%;
  padding: 9px 0 0 0;
}
.shareOptions {
  float: left;
  width: 100%;
  padding: 9px 0 10px 0;
  min-height: 295px;
  max-height: 295px;
  overflow: auto;
}
.shareOptionsCol {
  float: left;
  width: 100%;
  padding: 0 10px 0 0;
}
.emailToLabel > div {
  width: 100%;
}
.emailToLabel button {
  padding: 0;
  margin: 0 0 0 2px;
  cursor: pointer;
}
.shareList {
  min-width: 560px;
}
.tabGroupDropdown {
  display: none;
}
.mobile-heading-view-by {
  display: none;
}
// stepsFlow
.stepFlowRow {
  float: left;
  width: 100%;
  z-index: 3;
  position: relative;
  grid-row: 1 / -1;
}
.stepLabelRow {
  float: left;
  width: 100%;
  margin: 0 0 0 30px;
  display: flex;
  justify-content: space-between;
  opacity: 0.4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 13px 0 13px 0;
}
.stepFlowCol {
  float: left;
  width: 100%;
}
.stepColumn {
  display: flex;
  float: left;
  width: 100%;
  padding: 0 0 0 1px;
  align-items: center;
}
.stepLabel {
  font-size: 14px;
  color: var(--color-main);
  font-weight: bold;
  padding: 5px 0 0 0;
}
.stepCircle {
  width: 32px;
  height: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepCircleIcon i {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.2);
}
.stepLine {
  position: absolute;
  height: 100%;
  background: transparent;
  border-left: 2px dashed rgba(0, 0, 0, 0.2);
  width: 3px;
  z-index: 2;
  top: 53px;
  left: 16px;
  grid-row: 1 / 3;
}
.stepCircleBdr {
  background: transparent;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepCircleBox {
  height: 10px;
  width: 10px;
  background: rgba(0, 0, 0, 0.13);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-active .stepCircleBox {
  background: var(--color-main);
  width: 100%;
  height: 100%;
}
.step-active .stepCircleBox i {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.99);
}

.step-active .stepLabelDetails {
  color: rgba(0, 0, 0, 0.9);
}
.step-current .stepCircleBox {
  background: rgba(0, 0, 0, 0.6);
}
.step-current .stepCircleBox i {
  color: #fff;
}
.step-current .stepLabelDetails {
  color: rgba(0, 0, 0, 0.7);
}
.stepDetailBox {
  float: left;
  width: 100%;
  padding: 10px 0 20px 0;
}
.stepFlowGroup {
  position: relative;
  float: left;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}
.stepLabelDetails {
  float: left;
  width: 100%;
  padding: 5px 0 1px 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
}
.stepRowText {
  float: left;
  width: 86%;
}
.stepBtn {
  text-align: right;
}
.stepBtn button {
  margin: 0 0 7px 0;
  min-width: 70px;
}
.step-active .stepLabelRow {
  opacity: 0.85;
}
.step-current .stepLabelRow {
  opacity: 1;
}
.opacity45 {
  opacity: 0.45;
}
.stepFlow {
  float: left;
  width: 100%;
  background: rgba(0, 0, 0, 0.02);
  padding: 15px 20px;
  box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.stepMain {
  padding: 5px 20px 5px 10px;
  float: left;
  width: 90%;
}
.trk-title {
  float: left;
  width: 100%;
  font-size: 18px;
  margin: 0;
}

.mxLabel .mx-td-spn {
  max-height: 30px;
}
.k-timepicker-popup{
  padding-right: 5px;
}
.k-timepicker-popup .k-time-list-container .k-title {
  font-size: 12px;
}

.dialogAuth .logo {
  height: 50px;;
}
.dialogAuth .k-dialog-titlebar .k-dialog-actions {
  display: none;
}

.DropDownButton-popup {
  .k-menu-group {
    padding: 0 10px;
    .k-menu-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      &:last-child {
        border-bottom: 0;
      }
      &:focus, &.k-focus, &:focus, &.k-focus,
      &:hover, &.k-hover, &:hover, &.k-hover {
        background-color: transparent;
        color: inherit;
      }
      .k-menu-link {
        &:focus, &.k-focus, &:focus, &.k-focus,
        &:hover, &.k-hover, &:hover, &.k-hover {
          background-color: transparent;
        }
      }
    }
  }
}