.k-stepper .k-step-done .k-step-indicator, .k-stepper .k-step-done:hover .k-step-indicator {
    border-color: var(--color-main);
    background-color: var(--color-main);
}

.k-stepper .k-step-current .k-step-indicator, .k-stepper .k-step-current:hover .k-step-indicator {
    border-color: var(--color-main);
    background-color: var(--color-main);
}

.k-progressbar {
    background-color: #dadada;
}

.k-progressbar .k-selected {
    background-color: var(--color-main);
}