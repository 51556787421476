// playerFull
// body {
//   background: #2b3c65;
// }
.k-tabstrip-items {
  font-family: "Poppins" !important;
  text-transform: initial;
}
.wordCloudDiv {
  width: 100%;
  height: 100%;
}
.wordCloudDiv .wordcloud {
  width: 100%;
  height: 100%;
}
.bgBody {
  background: #2b3c65;
  height: 100%;
  padding: 15px;
}
.darkPlayer .cardBody {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.darkPlayer .k-link {
  color: rgba(255, 255, 255, 0.5);
}
.darkPlayer
  .tab-line
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item.k-active
  .k-link {
  color: #68aaf2;
}
.darkPlayer .tab-line .k-tabstrip-items-wrapper .k-item.k-active::after {
  border-color: #68aaf2;
}
.darkPlayer .k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.darkPlayer .spkTrk-para {
  color: rgba(255, 255, 255, 0.5);
  border-left: none;
}
.darkPlayer .spkTrk.active .spkTrk-para {
  color: rgba(255, 255, 255, 0.65);
  border-left: 2px solid rgba(255, 255, 255, 0.65);
}
.callPlayerFull .k-animation-container {
  float: left;
  width: 100%;
}
.darkPlayer .detailHeader {
  color: rgba(255, 255, 255, 0.88);
}
.callPlayerFull .liDetail-data .liDetail-p {
  color: rgba(255, 255, 255, 0.4);
}
.callPlayerFull .listInr-detail {
  flex-wrap: wrap;
}
.callPlayerFull .playerBtnGroup .PlayerButton {
  background: #68aaf2;
}
.callPlayerFull .playerBtnGroup .PlayerButton {
  background: #68aaf2;
  border-color: #68aaf2;
  height: 45px;
  min-width: 45px;
}
.darkPlayer .legends {
  color: rgba(255, 255, 255, 0.85);
}
.darkPlayer .legends .list-unstyled {
  color: rgba(255, 255, 255, 0.5);
}
.cardHeading {
  font-size: 15px;
  font-weight: 600;
}
.cardSummaryRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 12px 0 12px 0;
}
.cardSummaryCol {
  border-left: 4px solid rgba(0, 0, 0, 0.3);
  padding: 0 0 0 14px;
  font-size: 15px;
}
.cardSummary {
  padding: 0 10px 15px 10px;
}
.pointCol {
  display: flex;
  align-items: self-start;
}
.pointCol i {
  font-size: 22px;
  padding: 0 10px 0 0;
  display: flex;
  align-items: self-start;
}
.pointColTxt {
  font-size: 14px;
  line-height: 17px;
}
.pointRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 0 10px 0;
}
.textLabels .keywordTrk {
  margin: 0 8px 8px 0;
}
.darkPlayer .card {
  background: rgba(0, 0, 0, 0.2);
  border: none;
}
.darkPlayer .cardHeading {
  color: rgba(255, 255, 255, 0.6);
}
.darkPlayer .cardSummaryCol {
  color: rgba(255, 255, 255, 0.5);
  border-left-color: rgba(255, 255, 255, 0.2);
}
.darkPlayer .pointColTxt {
  color: rgba(255, 255, 255, 0.5);
}
.darkPlayer .pointCol i {
  color: rgba(255, 255, 255, 0.35);
}
.darkPlayer .textLabels .keywordTrk {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(0, 0, 0, 1);
}
.wordCloudDiv svg {
  width: 100%;
  height: 100%;
}
// .wordCloudDiv svg g {
//   transform: translate(155px, 80px) scale(1.2);
// }
.darkPlayer .card-header {
  border-color: rgba(255, 255, 255, 0.1);
}
.darkPlayer .cardSummaryRow {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}
.darkPlayer .pointRow {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}
.hBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.hBoxButton {
  background: transparent;
  border: transparent;
  font-size: 20px !important;
  padding: 0;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
}
.bgUserBox {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 50px;
  padding: 5px;
}
