/*settingData*/
.settingData .nav-tabs .nav-item .nav-link {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  margin: 0;
  max-width: 200px;
  min-width: 130px;
}
.settingData .nav-link span {
  float: right;
  width: 100%;
}
.settingData .nav-tabs .nav-item i {
  width: 24px;
  height: 24px;
  margin: 0 4px 0 10px;
  font-size: 18px;
}
.settingData .tabs-line .nav-tabs .nav-link.active {
  border-right: 2px solid var(--color-main);
  color: var(--color-main);
  background: transparent;
  border-bottom: 2px solid transparent;
  font-weight: 600;
  font-size: 13.5px;
  border-bottom: 0;
}
.settingData .tabs-line .nav-tabs .nav-link.active i {
  margin: 0 2px 0 10px;
}
.settingData .tabs-line .nav-tabs .nav-link.active i {
  color: var(--color-main);
  fill: var(--color-main);
}
.settingData .tabs-line .nav-tabs .nav-item.nv-security .nav-link.active i {
  color: var(--color-main);
  fill: none;
  stroke: var(--color-main);
}
.settingData {
  float: left;
  width: 100%;
  padding: 2px 0 0 0;
}
.settingData .tabs-line.tabs-vertical .tab-content {
  padding: 0px 10px 5px 40px;
  min-height: 300px;
}
.setForm {
  float: left;
  width: 100%;
  position: relative;
}
.setForm-title {
  font-size: 18px;
  margin: 0 0 30px 0;
}
.setFormInr {
  float: left;
  width: 100%;
  position: relative;
}
.setFormTrk {
  float: left;
  width: 100%;
  padding: 0 0 20px 0;
}
.setFormCol-h {
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 3px 0;
  color: #333;
}
.setFormInput {
  float: left;
  width: 100%;
}
.setFormInput input {
  float: left;
  width: 100%;
  padding: 6px 10px 6px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.setFormInput input:focus-visible {
  border: 1px solid #84a7bd;
  outline: none;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  box-shadow: 0 0 2px rgba(95, 111, 121, 0.8);
}
.setFormCol-hh {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  padding: 0 0 20px 0;
}
.setFormInput .k-dropdown-wrap {
  float: left;
  width: 100%;
  position: relative;
  padding: 0;
  background: none;
  border: none;
  height: 35px;
}
.setFormInput .form-group {
  float: left;
  width: 100%;
}
.setFormInput .k-widget.k-combobox {
  background: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.setFormInput .k-input {
  background: none;
  border-radius: 4px !important;
}
.tableStore.tbl_rowWise table th {
  background: rgba(0, 0, 0, 0.025) !important;
  padding: 10px 15px 10px 10px;
  white-space: nowrap;
}
.tableStore.tbl_rowWise table td {
  padding: 10px 15px 10px 10px;
}
.tableStore.tbl_rowWise {
  box-shadow: 0 1px 5px 0 rgba(138, 155, 165, 0.35);
}
.settingData .nav-tabs .nav-link.active:after,
.nav-tabs .nav-item.show .nav-link:after {
  content: none;
}
.valLink {
  font-size: 16px;
  border: 1px solid #ced4da;
  padding: 3px 10px;
  border-radius: 5px;
  color: var(--color-main);
}
.valLink i {
  font-size: 15px;
}
/*settingData end*/
// tab vertical
.tabs-line .nav-tabs {
  border: none;
  z-index: 9;
  position: relative;
}

.tabs-line .nav-tabs .nav-link {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  cursor: pointer;
  text-decoration: none;
  border-left: none;
  border-right: none;
  border-top: none;
  position: relative;
}

.tabs-line .nav-tabs .nav-link.active {
  border-bottom: 2px solid var(--color-main);
  color: var(--color-main);
  background: transparent;
}

.tabs-line.tabs-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.tabs-line.tabs-vertical .nav-tabs .nav-link {
  padding: 0.9rem 1rem 0.5rem 0;
}

.tabs-line.tabs-vertical .nav-tabs .nav-link.active {
  padding: 0.9rem 1rem 0.5rem 0;
}

.tabs-line.tabs-vertical.tabs-vertical-left .nav-tabs .nav-link {
  border-right: 2px solid transparent;
  border-bottom: none;
}

.tabs-line.tabs-vertical.tabs-vertical-left .nav-tabs .nav-link.active {
  border-right: 2px solid #038ee7;
  border-bottom: none;
}

.tabs-line.tabs-vertical .nav-tabs {
  float: left;
  display: block;
}

.tabs-line.tabs-vertical .tab-content {
  float: left;
  flex: auto;
  border-left: 2px solid #eee;
  padding: 8px 0 10px 20px;
  margin: 0 0 0 -2px;
}
// tab vertical end
.settingData .tabs-line.tab-btn .nav-tabs .nav-link i {
  display: none;
}
.settingData .tabs-line.tab-btn .nav-tabs .nav-link {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.7);
  padding: 5px 20px;
  border-radius: 5px;
  text-align: center;
  margin: 0 0 15px 0;
  border: none;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.settingData .tabs-line.tab-btn .nav-tabs .nav-link.active {
  background: var(--color-main);
  color: rgba(255, 255, 255, 0.9);
  font-weight: normal;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--color-main);
}
.settingData .tabs-line.tab-btn .nav-tabs {
  padding: 0 20px 0 0;
}
.tabs-card .nav .nav-item {
  font-size: 14px;
  margin: 0 5px 0 20px;
}
.tabs-card .nav .nav-item a {
  margin: 0;
  padding: 14px 0 13px 0;
}
.tabs-card .nav-tabs .nav-link.active:after,
.nav-tabs .nav-item.show .nav-link:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: var(--color-main);
  left: 0;
  bottom: -2px;
  width: 100%;
}
// kendo react ui tab
.k-custom-tab .k-tabstrip > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  padding: 0 13px 0 0;
}
.k-custom-tab .k-tabstrip-items-wrapper {
  padding: 0 15px 0 20px;
}
.k-custom-tab .k-tabstrip-left > .k-tabstrip-items-wrapper .k-item::after {
  border-right-width: 0;
  border: none;
}
.k-custom-tab
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item {
  margin: 0 0 15px 0;
}
.k-custom-tab
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item
  .k-link {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.7) !important;
  padding: 5px 5px !important;
  border-radius: 4px;
  text-align: center;
  border: none;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  max-width: 280px;
  min-width: 160px;
  text-transform: initial;
  justify-content: center;
  font-size: 14px !important;
  font-weight: normal;
}
.k-custom-tab
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item.k-active
  .k-link {
  background: var(--color-main);
  color: rgba(255, 255, 255, 0.9) !important;
  font-weight: normal !important;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--color-main);
}
.tab-line .k-custom-tab .k-tabstrip-items-wrapper .k-item.k-active::after {
  border-bottom: 0 !important;
}
.k-custom-tab .k-tabstrip .k-content {
  padding: 0px 15px 5px 30px;
}
.k-custom-tab .k-animation-container-relative {
  display: block;
  border-radius: 0;
}
// tab-line
.tab-line
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item
  .k-link {
  font-weight: 500;
  font-size: 15px;
  padding: 14px 20px;
}
.tab-line
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item.k-active
  .k-link {
  color: var(--color-main);
  font-weight: 500;
}
.tab-line .k-tabstrip-items-wrapper .k-item.k-active::after {
  border-bottom: 3px solid var(--color-main);
}
.maxTabScroll .k-tabstrip-items {
  max-height: 400px;
  overflow: hidden auto;
  display: block !important;
}
.tabAction {
  min-width: 160px;
  max-width: 160px;
}
.tabAction button {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.minHeightTab35
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item {
  min-height: 35px;
}
// accordian start
.expandableNav .k-panelbar-item-text {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  font-family: "Poppins" !important;
}
.expandableNav .k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link {
  cursor: pointer;
  padding: 12px 10px 12px 15px;
  justify-content: center;
}
.expandableNav .k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link.k-selected {
  color: #333;
  background-color: #fff;
}
.expandableNav .k-panelbar > .k-item > .k-link.k-selected:focus,
.k-panelbar > .k-item > .k-link.k-selected.k-focus,
.k-panelbar > .k-panelbar-header > .k-link.k-selected:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-focus {
  background-color: #fff;
}
.expandableNav .k-panelbar > .k-item > .k-link:focus,
.k-panelbar > .k-item > .k-link.k-focus,
.k-panelbar > .k-panelbar-header > .k-link:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-focus {
  background-color: #fff;
}
.expandableNav .k-panelbar > .k-item > .k-link:hover,
.k-panelbar > .k-item > .k-link.k-hover,
.k-panelbar > .k-panelbar-header > .k-link:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-hover {
  background-color: #fff;
}
.k-panelbar > .k-item > .k-link.k-selected:hover,
.k-panelbar > .k-item > .k-link.k-selected.k-hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover {
  background-color: #fff;
}
.k-panelbar .k-group,
.k-panelbar .k-panelbar-group {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0;
  // padding: 7px 10px 7px 10px;
}
// .expandableNav.navTitleCenter .k-panelbar .k-group,
// .k-panelbar .k-panelbar-group {
//   padding: 7px 10px 7px 10px;
// }
.expandableNav.navTitleCenter.k-panelbar .k-panelbar-expand,
.k-panelbar .k-panelbar-collapse,
.k-panelbar .k-panelbar-toggle {
  float: right;
  position: absolute;
  right: 10px;
}
.accordionData .k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link {
  justify-content: start;
}
.accordionData .k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header.k-expanded > .k-link {
  background-color: rgba(0, 0, 0, 0.015);
}
.accordionData .k-content {
  background-color: rgba(0, 0, 0, 0.015);
}
.accordionData .k-panelbar {
  border-color: rgba(0, 0, 0, 0.08);
}
// accordian end

// itemBoxButtons start
.itmeBoxBtn
  .k-tabstrip
  > .k-tabstrip-items-wrapper
  > .k-tabstrip-items
  .k-item {
  margin: 0 10px 15px 0;
}
.itmeBoxBtn .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item::after {
  border-bottom-width: 0;
}
.itmeBoxBtn .k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom-width: 0;
}
.itmeBoxBtn .k-tabstrip .k-content {
  padding: 0;
}
// itemBoxButtons end
.expandBox .k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link {
  padding: 7px 10px 7px 15px;
  border-radius: 0.25rem !important;
}
.expandBox .k-panelbar-item-text {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  font-family: "Poppins" !important;
}
// tabWithIconImg
.tabIcsAnalyticsImg {
  height: 22px;
  max-width: 70px;
  padding: 0 2px 0 0;
}
.tabIcsAnalytics {
  display: flex;
  align-items: center;
  justify-content: center;
}
.k-tabstrip-left
  > .k-tabstrip-items-wrapper
  .k-item.k-active
  .tabIcsAnalytics
  .tabIcsAnalyticsImg {
  filter: brightness(0) invert(1);
}
